import {
  Box,
  Button,
  Checkbox,
  Typography,
  CircularProgress,
  FormControlLabel,
  Container,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import OwnerHeader from "../Components/OwnerHeader";
import { useLocation, useParams } from "react-router-dom";
import GlobalContext from "../../../Context/globalContext";
import { imageUrl, Instance } from "../../../Config";
import { SHOW_SNACKBAR } from "../../../Context/globalActions.types";
import useSWR from "swr";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";

const OwnerOrderDetails = () => {
  const location = useLocation();
  const params = useParams();

  const [data, setData] = useState({});
  const [orderItmStatus, setOrderItmStatus] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");

  const [selectAll, setSelectAll] = useState(false);

  const { globalContext, dispatch } = useContext(GlobalContext);

  // const fetchDetailData = () => {
  //   const data = globalContext.orders.filter(
  //     (order) => order.orderId == Number(location.search.split("=")[1])
  //   );
  //   if (data) {
  //     setData(data[0]);
  //   } else {
  //     history.push(`/${params.mobileNo}/owner/orders`);
  //   }
  // };

  function getQueryVariable(variable) {
    var query = location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  const { data: resOwnerOrders, error: resOwnerOrdersError } = useSWR(
    `/orders/${getQueryVariable("id")}?mobileNo=${params.mobileNo}`
  );

  // const changeOrderStatus = () => {
  //   Instance.put(`/orders/status?mobileNo=${params.mobileNo}`, {
  //     id: data.orderId,
  //     status: orderStatus,
  //     type: "order",
  //   })
  //     .then((res) => {
  //       if (res.status == 200) {
  //         data.STATUS = orderStatus;
  //         setData(data);
  //       }
  //       setOrderStatus("");
  //     })
  //     .catch((e) => {
  //       dispatch({
  //         type: SHOW_ERROR,
  //         payload: "Something Went wrong",
  //       });
  //       setTimeout(() => {
  //         dispatch({
  //           type: HIDE_ERROR,
  //         });
  //       }, 5000);
  //     });
  // };

  const [excelExportData, setExcelExportData] = useState([]);
  const [pdfExportData, setPdfExportData] = useState([]);
  const [isLoadingExportData, setIsLoadingExportData] = useState(true);
  const headers = [
    { label: "Order Id", key: "ORDER_NO" },
    { label: "Customer Name", key: "accounts.ACC_NAME" },
    { label: "Item Name", key: "ITEM_NAME" },
    { label: "Quantity", key: "QUANTITY" },
    { label: "Delivered Qty", key: "DLVR_QUANTITY" },
    { label: "Size", key: "SIZE_CD" },
    { label: "Status", key: "ISTATUS" },
    { label: "Order Date", key: "createdAt" },
  ];

  const exportPdf = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    let headers;
    let data;

    if (
      globalContext.settings.filter((e) => e.VARIABLE == "prol")[0].VALUE == "Y"
    ) {
      headers = [
        [
          "Item Name",
          "Size",
          "Box",
          "Per Box Qty",
          "Quantity",
          "Delivered Qty",
          "Status",
        ],
      ];

      data = pdfExportData.map((e) => [
        e.ITEM_NAME,
        e.SIZE_CD,
        e.QUANTITY,
        e.DESC1,
        Number(e.QUANTITY) * Number(e.DESC1),
        e.DLVR_QUANTITY,
        e.ISTATUS,
      ]);
    } else {
      headers = [["Item Name", "Size", "Quantity", "Delivered Qty", "Status"]];

      data = pdfExportData.map((e) => [
        e.ITEM_NAME,
        e.SIZE_CD,
        e.QUANTITY,
        e.DLVR_QUANTITY,
        e.ISTATUS,
      ]);
    }

    let content = {
      startY: 120,
      head: headers,
      body: data,
    };

    const width1 = doc.internal.pageSize.getWidth();

    doc.text(globalContext.company[0].COMP_NAME, width1 / 2, 50, {
      align: "center",
    });
    doc.setFontSize(9);
    doc.text(globalContext.company[0].ADDRS, width1 / 2, 65, {
      align: "center",
    });
    doc.text(`Order Id: ${pdfExportData[0].ORDER_NO}`, marginLeft, 90);
    doc.text(
      `Order Date: ${new Date(pdfExportData[0].createdAt).toLocaleString("hi", {
        dateStyle: "short",
      })}`,
      width1 - 140,
      90
    );
    doc.text(
      `Customer Name: ${pdfExportData[0].accounts.ACC_NAME}`,
      marginLeft,
      100
    );
    doc.text(
      `Address: ${
        (pdfExportData[0].accounts.ADD1 ? pdfExportData[0].accounts.ADD1 : "") +
        " " +
        (pdfExportData[0].accounts.ADD2 ? pdfExportData[0].accounts.ADD2 : "") +
        " " +
        (pdfExportData[0].accounts.AREA ? pdfExportData[0].accounts.AREA : "") +
        " " +
        (pdfExportData[0].accounts.CITY ? pdfExportData[0].accounts.CITY : "") +
        " " +
        (pdfExportData[0].accounts.PINCODE
          ? pdfExportData[0].accounts.PINCODE
          : "")
      }`,
      marginLeft,
      110
    );

    doc.setFontSize(15);
    doc.autoTable(content);
    doc.save(`order_${pdfExportData[0].ORDER_NO}.pdf`);
  };

  const changeOrderItmStatus = (status) => {
    Instance.put(`/orders/status?mobileNo=${params.mobileNo}`, {
      id: orderItmStatus,
      status: status,
      type: "orderitm",
    })
      .then((res) => {
        if (res.status == 200) {
          for (let i = 0; i < orderItmStatus.length; i++) {
            const oitmIndex = data.orderItm.findIndex(
              (e) => e.oitmid == orderItmStatus[i]
            );
            data.orderItm[oitmIndex].ISTATUS = status;
            setData(data);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Something went wrong",
            type: "error",
          },
        });
      })
      .then(() => {
        setOrderItmStatus([]);
      });
  };

  const createExportData = (data) => {
    return new Promise((resolve) => {
      const tempData = [];
      for (let i = 0; i < data.orderItm.length; i++) {
        tempData.push({
          ITEM_NAME: data.orderItm[i].item.ITEM_NAME,
          SIZE_CD: data.orderItm[i].SIZE_CD,
          QUANTITY: data.orderItm[i].QUANTITY,
          DLVR_QUANTITY: data.orderItm[i].DLVR_QUANTITY,
          ISTATUS: data.orderItm[i].ISTATUS,
          ORDER_NO: data.ORDER_NO,
          accounts: data.accounts,
          createdAt: data.createdAt,
          NET_AMOUNT: data.orderItm[i].NET_AMOUNT,
          RATE: data.orderItm[i].RATE,
          DESC1: data.orderItm[i].item.variant.find(
            (e) => e.SIZE_CD == data.orderItm[i].SIZE_CD
          )
            ? data.orderItm[i].item.variant.find(
                (e) => e.SIZE_CD == data.orderItm[i].SIZE_CD
              ).DESC1
            : "",
        });
      }
      resolve(tempData);
    });
  };

  const createExcelExportData = (data) => {
    return new Promise((resolve) => {
      const tempData = [];

      if (
        globalContext.settings.filter((e) => e.VARIABLE == "prol")[0].VALUE ==
        "Y"
      ) {
        tempData.push([
          "Order Id",
          data.ORDER_NO,
          "",
          "",
          "Customer Name",
          data.accounts.ACC_NAME,
          "",
        ]);
        tempData.push([
          "Order Date",
          new Date(data.createdAt).toLocaleString("hi", {
            dateStyle: "short",
          }),
          "",
          "",
          "Address:",
          (data.accounts.ADD1 ? data.accounts.ADD1 : "") +
            " " +
            (data.accounts.ADD2 ? data.accounts.ADD2 : "") +
            " " +
            (data.accounts.AREA ? data.accounts.AREA : "") +
            " " +
            (data.accounts.CITY ? data.accounts.CITY : "") +
            " " +
            (data.accounts.PINCODE ? data.accounts.PINCODE : ""),
          "",
        ]);
        tempData.push([
          "Item Name",
          "Size",
          "Box",
          "Per Box Qty",
          "Quantity",
          "Delivered Box",
          "Status",
        ]);
        for (let i = 0; i < data.orderItm.length; i++) {
          tempData.push([
            data.orderItm[i].item.ITEM_NAME,
            data.orderItm[i].SIZE_CD,
            data.orderItm[i].QUANTITY,
            data.orderItm[i].item.variant.find(
              (e) => e.SIZE_CD == data.orderItm[i].SIZE_CD
            ).DESC1,
            Number(data.orderItm[i].QUANTITY) *
              Number(
                data.orderItm[i].item.variant.find(
                  (e) => e.SIZE_CD == data.orderItm[i].SIZE_CD
                ).DESC1
              ),
            data.orderItm[i].DLVR_QUANTITY,
            data.orderItm[i].ISTATUS,
          ]);
        }
      } else {
        tempData.push([
          "Order Id",
          data.ORDER_NO,
          "Customer Name",
          data.accounts.ACC_NAME,
          "",
        ]);
        tempData.push([
          "Order Date",
          new Date(data.createdAt).toLocaleString("hi", {
            dateStyle: "short",
          }),
          "Address:",
          (data.accounts.ADD1 ? data.accounts.ADD1 : "") +
            " " +
            (data.accounts.ADD2 ? data.accounts.ADD2 : "") +
            " " +
            (data.accounts.AREA ? data.accounts.AREA : "") +
            " " +
            (data.accounts.CITY ? data.accounts.CITY : "") +
            " " +
            (data.accounts.PINCODE ? data.accounts.PINCODE : ""),
          "",
        ]);
        tempData.push([
          "Item Name",
          "Size",
          "Quantity",
          "Delivered Box",
          "Status",
        ]);
        for (let i = 0; i < data.orderItm.length; i++) {
          tempData.push([
            data.orderItm[i].item.ITEM_NAME,
            data.orderItm[i].SIZE_CD,
            data.orderItm[i].QUANTITY,
            data.orderItm[i].DLVR_QUANTITY,
            data.orderItm[i].ISTATUS,
            // ORDER_NO: data.ORDER_NO,
            // PARTY_CD: data.PARTY_CD,
            // PAYMENT_MODE: data.PAYMENT_MODE,
            // SMAN_CD: data.SMAN_CD,
            // accounts: data.accounts,
            // orderId: data.orderId,
            // createdAt: data.createdAt,
            // DEPT_CD: data.orderItm[i].DEPT_CD,
            // ITEM_CD: data.orderItm[i].ITEM_CD,
            // NET_AMOUNT: data.orderItm[i].NET_AMOUNT,
            // RATE: data.orderItm[i].RATE,
            // dept_name: data.orderItm[i].item.dept.dept_name,
          ]);
        }
      }

      resolve(tempData);
    });
  };

  useEffect(() => {
    if (resOwnerOrders) {
      setData(resOwnerOrders.data.result[0]);

      createExportData(resOwnerOrders.data.result[0]).then((newExportData) => {
        setPdfExportData(newExportData);
        createExcelExportData(resOwnerOrders.data.result[0]).then(
          (newExportData) => {
            console.log(newExportData);
            setExcelExportData(newExportData);
            setIsLoadingExportData(false);
          }
        );
      });
    }
  }, [resOwnerOrders]);

  return (
    <div>
      <OwnerHeader>
        <div className="px-3 md:px-4 py-2 md:py-3 md:flex justify-between items-end">
          <div>
            <div className="text-indigo-500 font-semibold text-sm md:text-base">
              Order Details
            </div>
            <div className="text-xl md:text-2xl font-extrabold leading-7 sm:leading-10 truncate mt-1">
              Order No : #
              {resOwnerOrders ? (data.ORDER_NO ? data.ORDER_NO : "") : ""}
            </div>
          </div>
          {isLoadingExportData ? (
            <CircularProgress size={20} />
          ) : (
            <div className="pb-2">
              <Button
                variant="outlined"
                className="text-blue-600 border-[#3333cc] rounded-full mt-2 md:mt-0"
                startIcon={<SaveAltIcon />}
              >
                <CSVLink
                  data={excelExportData}
                  // headers={[]}
                  filename={`order_${excelExportData[0][1]}.csv`}
                  className="text-blue-600"
                >
                  Excel
                </CSVLink>
              </Button>
              <Button
                variant="outlined"
                className="text-blue-600 border-[#3333cc] rounded-full mt-2 md:mt-0 ml-2"
                startIcon={<InsertDriveFileOutlinedIcon />}
                onClick={() => {
                  exportPdf();
                }}
              >
                PDF
              </Button>
            </div>
          )}
        </div>
        {resOwnerOrders ? (
          <Container
            maxWidth="xl"
            className="pt-2 bg-[#F1F5F9] min-h-screen pb-5 pr-3 pl-2"
          >
            <div className="md:flex items-start text-black">
              <Box className="bg-white rounded-xl px-6 py-2 text-black md:w-3/4 border-[#3333cc] border-[.5px]">
                <div className="flex justify-between items-center">
                  <Typography variant="h5" className="font-bold">
                    Order Items
                  </Typography>
                  {data.orderItm ? (
                    data.orderItm.find((e) => e.ISTATUS == "Pending") ? (
                      <div>
                        {orderItmStatus.length != 0 ? (
                          <>
                            <Button
                              variant="contained"
                              className="mr-2 my-2 md:my-0 bg-[#ff6600] text-white"
                              onClick={() => {
                                changeOrderItmStatus("Canceled");
                              }}
                            >
                              Cancel Item
                            </Button>
                            <Button
                              variant="contained"
                              className="bg-[#049aab] text-white"
                              onClick={() => {
                                changeOrderItmStatus("Dispatched");
                              }}
                            >
                              Dispatch Item
                            </Button>
                          </>
                        ) : null}
                        <FormControlLabel
                          className="mt-2 ml-1"
                          control={
                            <Checkbox
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectAll(true);
                                  data.orderItm.map((product) => {
                                    if (product.ISTATUS == "Pending") {
                                      orderItmStatus.push(product.oitmid);
                                      setOrderItmStatus([...orderItmStatus]);
                                    }
                                  });
                                } else {
                                  setSelectAll(false);
                                  setOrderItmStatus([]);
                                }
                              }}
                              iconStyle={{ fill: "#3333cc" }}
                              size="small"
                            />
                          }
                          label="Select All"
                          checked={selectAll}
                        />
                      </div>
                    ) : null
                  ) : null}
                </div>
                {/* {orderItmStatus.length != 0 ? (
                  <div className="bg-pink-200 px-3 py-1 rounded flex flex-wrap justify-between items-center">
                    <div className="flex">
                      <div
                        className="border rounded border-black mr-4 cursor-pointer"
                        onClick={() => {
                          setOrderItmStatus([]);
                        }}
                      >
                        <CloseIcon />
                      </div>
                      <div className="text-lg">
                        {orderItmStatus.length} Selected
                      </div>
                    </div>
                    <div>
                      <Button
                        variant="outlined"
                        className="mr-4 my-2 md:my-0"
                        onClick={() => {
                          changeOrderItmStatus("Canceled");
                        }}
                      >
                        Cancel Item
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          changeOrderItmStatus("Dispatched");
                        }}
                      >
                        Dispatch Item
                      </Button>
                    </div>
                  </div>
                ) : null} */}

                <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                  {typeof data.orderItm != "undefined"
                    ? data.orderItm.map((product, index) => (
                        <div
                          className="flex flex-row bg-white rounded-md border-[.5px] md:max-w-sm border-[#3333cc] my-2"
                          key={index}
                        >
                          {product.img ? (
                            <img
                              className="object-cover h-auto rounded-t-lg w-20 md:w-24 md:rounded-none md:rounded-l-lg p-1"
                              src={imageUrl + "/" + product.img.IMGURL[0]}
                              alt={product.ITEM_NAME}
                            />
                          ) : (
                            <img
                              className="object-cover h-auto rounded-t-lg w-20 md:w-24 md:rounded-none md:rounded-l-lg p-1"
                              src="/img/11.jpg"
                              alt="Image Not Found"
                            />
                          )}

                          <div className="flex flex-col justify-start px-2 py-2 w-full">
                            <Typography
                              variant="body1"
                              className="text-xs font-bold text-gray-900 md:leading-4 leading-none "
                            >
                              <div className="flex items-start justify-between">
                                <div>
                                  {product.ITEM_NAME.toString().slice(0, 17)}
                                  {product.ITEM_NAME.toString().length > 17
                                    ? "..."
                                    : ""}
                                </div>
                                <div>
                                  {product.ISTATUS == "Pending" ? (
                                    <Checkbox
                                      checked={
                                        orderItmStatus.find(
                                          (e) => e == product.oitmid
                                        )
                                          ? true
                                          : false
                                      }
                                      className="p-0"
                                      iconStyle={{ fill: "#3333cc" }}
                                      size="small"
                                      value={product.oitmid}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setOrderItmStatus([
                                            ...orderItmStatus,
                                            e.target.value,
                                          ]);
                                        } else {
                                          setOrderItmStatus(
                                            orderItmStatus.filter(
                                              (a) => a != e.target.value
                                            )
                                          );
                                        }
                                      }}
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </Typography>
                            <Typography
                              variant="body2"
                              className="text-xs font-normal text-gray-700"
                            >
                              <span className="font-bold">Size Cd :</span>{" "}
                              {product.SIZE_CD}
                            </Typography>
                            <Typography
                              variant="body2"
                              className="text-xs font-normal text-gray-700 mt-0.5"
                            >
                              <span className="font-bold">Qty :</span>{" "}
                              {product.QUANTITY}{" "}
                              {globalContext.settings.length != 0 ? (
                                globalContext.settings.filter(
                                  (e) => e.VARIABLE == "prol"
                                )[0].VALUE == "Y" ? (
                                  <span className="small mt-1 ml-2">
                                    ({product.QUANTITY} X{" "}
                                    {
                                      product.item.variant.find(
                                        (e) => e.SIZE_CD == product.SIZE_CD
                                      ).DESC1
                                    }{" "}
                                    ={" "}
                                    {product.QUANTITY *
                                      product.item.variant.find(
                                        (e) => e.SIZE_CD == product.SIZE_CD
                                      ).DESC1}
                                    )
                                  </span>
                                ) : null
                              ) : null}
                            </Typography>
                            <Typography
                              variant="body2"
                              className="text-xs font-normal text-gray-700 mt-0.5"
                            >
                              <span className="font-bold">Qty Delivered :</span>{" "}
                              {product.DLVR_QUANTITY}
                            </Typography>
                            <Typography
                              variant="body2"
                              className="text-xs font-normal text-gray-700 mt-0.5"
                            >
                              <span className="font-bold">Rate :</span>{" "}
                              {product.RATE}
                            </Typography>
                            <Typography
                              variant="body2"
                              className="text-xs font-normal text-gray-700 mt-0.5"
                            >
                              <span className="font-bold">Net Amt :</span>{" "}
                              {product.NET_AMOUNT}
                            </Typography>
                            <Typography
                              variant="body2"
                              className="text-xs font-normal text-gray-700 mt-2"
                            >
                              <span
                                className={
                                  product.ISTATUS == "Pending"
                                    ? "rounded-md px-2 py-1 bg-[#ff6600] text-white"
                                    : product.ISTATUS == "Dispatched"
                                    ? "rounded-md px-2 py-1 bg-[#049aab] text-white"
                                    : product.ISTATUS == "Canceled"
                                    ? "rounded-md px-2 py-1 bg-[#3333cc] text-white"
                                    : "rounded-md px-2 py-1 bg-[#009999] text-white"
                                }
                              >
                                {product.ISTATUS}
                              </span>
                            </Typography>
                          </div>
                        </div>
                      ))
                    : ""}
                </div>

                {/* <Table>
                  <TableHead>
                    <TableCell className="font-bold"></TableCell>
                    <TableCell className="font-bold">#</TableCell>
                    <TableCell className="font-bold">Product Desc</TableCell>
                    <TableCell className="font-bold">Qty</TableCell>
                    <TableCell className="font-bold">Rate</TableCell>
                    <TableCell className="font-bold">Amt</TableCell>
                    <TableCell className="font-bold">Status</TableCell>
                  </TableHead>
                  <TableBody>
                    {typeof data.orderItm != "undefined"
                      ? data.orderItm.map((product, index) => (
                          <TableRow key={index}>
                            <TableCell className="p-1">
                              <div>
                                {product.ISTATUS == "Pending" ? (
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      orderItmStatus.find(
                                        (e) => e == product.oitmid
                                      )
                                        ? true
                                        : false
                                    }
                                    size="small"
                                    value={product.oitmid}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setOrderItmStatus([
                                          ...orderItmStatus,
                                          e.target.value,
                                        ]);
                                      } else {
                                        setOrderItmStatus(
                                          orderItmStatus.filter(
                                            (a) => a != e.target.value
                                          )
                                        );
                                      }
                                    }}
                                  />
                                ) : null}
                              </div>
                            </TableCell>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              <div className="flex items-center">
                                {product.img ? (
                                  <img
                                    style={{
                                      minHeight: "3rem",
                                      maxHeight: "3rem",
                                      minWidth: "3rem",
                                      maxWidth: "5rem",
                                      objectFit: "fill",
                                    }}
                                    src={
                                      imageUrl +
                                      "/" +
                                      product.img.IMGURL.replace("[", "")
                                        .replace("]", "")
                                        .replace(/"/g, "")
                                        .split(",")[0]
                                    }
                                    alt={product.ITEM_NAME}
                                  />
                                ) : (
                                  <img
                                    style={{
                                      minHeight: "3rem",
                                      maxHeight: "3rem",
                                      minWidth: "3rem",
                                      maxWidth: "5rem",
                                      objectFit: "fill",
                                    }}
                                    src="/img/11.jpg"
                                    alt="Image Not Found"
                                  />
                                )}

                                <span className="ml-2 text-xs">
                                  {product.ITEM_NAME.toString().slice(0, 40)}
                                  {product.ITEM_NAME.toString().length > 40
                                    ? "..."
                                    : ""}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell className="text-xs">
                              {product.QUANTITY}
                            </TableCell>
                            <TableCell className="text-xs">
                              {product.RATE}
                            </TableCell>
                            <TableCell className="text-xs">
                              {product.NET_AMOUNT}
                            </TableCell>
                            <TableCell>
                              <span
                                className={
                                  product.ISTATUS == "Pending"
                                    ? "rounded-md p-2 bg-[#009999] text-white text-xs"
                                    : product.ISTATUS == "Dispatched"
                                    ? "rounded-md p-2 bg-[#3333cc] text-white text-xs"
                                    : product.ISTATUS == "Canceled"
                                    ? "rounded-md p-2 bg-[#ff6600] text-white text-xs"
                                    : "rounded-md p-2 text-xs"
                                }
                              >
                                {product.ISTATUS}
                              </span>
                            </TableCell>
                          </TableRow>
                        ))
                      : ""}
                  </TableBody>
                </Table> */}
              </Box>

              <div className="md:w-1/4 md:mt-0">
                <Typography variant="body2" className="p-0 m-0">
                  <Box className="bg-white rounded-xl px-3 py-3 md:mx-2 border-[#3333cc] border-[.5px] w-full">
                    <div className="flex justify-between">
                      <div className="font-bold flex-none">Order No :</div>
                      <div>#{data.ORDER_NO ? data.ORDER_NO : ""}</div>
                    </div>
                    <div className="flex justify-between">
                      <div className="font-bold flex-none">Created At :</div>
                      <div>
                        {new Date(data.createdAt).toLocaleString("us-en", {
                          dateStyle: "medium",
                          timeStyle: "medium",
                        })}
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="font-bold flex-none">Company Name :</div>
                      <div className="ml-1">
                        {data.accounts ? data.accounts.ACC_NAME : ""}
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="font-bold flex-none">Contact No : </div>
                      <div>
                        {data.accounts ? data.accounts.PHONE1 || "" : ""},{" "}
                        {data.accounts ? data.accounts.PHONE2 || "" : ""}
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="font-bold flex-none">Payment Mode:</div>
                      <div>{data.PAYMENT_MODE}</div>
                    </div>
                    <div className="flex justify-between">
                      <div className="font-bold flex-none">Total Items:</div>
                      <div>{data.NO_OF_ITEM}</div>
                    </div>
                    <div className="flex justify-between">
                      <div className="font-bold flex-none">SubTotal:</div>
                      <div>₹{data.ITEM_AMT}</div>
                    </div>
                    <div className="flex justify-between">
                      <div className="font-bold flex-none">Tax:</div>
                      <div>₹{data.NET_AMT - data.ITEM_AMT}</div>
                    </div>
                    <div className="flex justify-between">
                      <div className="font-bold flex-none">Net Amt:</div>
                      <div>₹{data.NET_AMT}</div>
                    </div>
                    {globalContext.settings.filter(
                      (e) => e.VARIABLE == "advancePaymentField"
                    )[0].VALUE == "Y" ? (
                      <div className="flex justify-between">
                        <div className="font-bold flex-none">Advance Amt:</div>
                        <div>₹{data.PAID_AMT ? data.PAID_AMT : 0}</div>
                      </div>
                    ) : null}
                    {globalContext.settings.filter(
                      (e) => e.VARIABLE == "checkoutRemarks1"
                    )[0].VALUE == "Y" ? (
                      <div className="flex justify-between">
                        <div className="font-bold flex-none">Remarks 1:</div>
                        <div>{data.REMARKS1}</div>
                      </div>
                    ) : null}
                    {globalContext.settings.filter(
                      (e) => e.VARIABLE == "checkoutRemarks2"
                    )[0].VALUE == "Y" ? (
                      <div className="flex justify-between">
                        <div className="font-bold flex-none">Remarks 2:</div>
                        <div>{data.REMARKS2}</div>
                      </div>
                    ) : null}
                    {globalContext.settings.filter(
                      (e) => e.VARIABLE == "checkoutRemarks3"
                    )[0].VALUE == "Y" ? (
                      <div className="flex justify-between">
                        <div className="font-bold flex-none">Remarks 3:</div>
                        <div>{data.REMARKS3}</div>
                      </div>
                    ) : null}
                  </Box>
                </Typography>
                <Typography variant="body2" className="w-full">
                  <Box className="bg-white rounded-xl px-3 py-3 my-2 md:mx-2 border-[#3333cc] border-[.5px] w-full">
                    <div className="font-bold">Delivery Address :</div>
                    <div>
                      {data.accounts
                        ? data.accounts.ADD1 +
                          data.accounts.ADD2 +
                          "," +
                          data.accounts.AREA +
                          "," +
                          data.accounts.CITY +
                          "-" +
                          data.accounts.PINCODE
                        : ""}
                    </div>
                  </Box>
                </Typography>
                {data.orderDtl ? (
                  <Typography variant="body2" className="w-full">
                    <Box className="bg-white rounded-xl px-3 py-3 mt-2 md:mx-2 border-[#3333cc] border-[.5px] w-full">
                      <div className="text-md">
                        <span className="font-bold">Name: </span>
                        <span> {data.orderDtl.NAME}</span>
                      </div>
                      <div className="text-md">
                        <span className="font-bold">Email: </span>
                        <span> {data.orderDtl.EMAIL}</span>
                      </div>
                      <div className="text-md">
                        <span className="font-bold">Mobile No: </span>
                        <span> {data.orderDtl.MOBILENO}</span>
                      </div>
                      <div className="text-md">
                        <span className="font-bold">Address 1: </span>
                        <span> {data.orderDtl.ADD1}</span>
                      </div>
                      <div className="text-md">
                        <span className="font-bold">Address 2: </span>
                        <span> {data.orderDtl.ADD2}</span>
                      </div>
                      <div className="text-md">
                        <span className="font-bold">City: </span>
                        <span> {data.orderDtl.CITY}</span>
                      </div>
                      <div className="text-md">
                        <span className="font-bold">State: </span>
                        <span> {data.orderDtl.STATE}</span>
                      </div>
                      <div className="text-md">
                        <span className="font-bold">Pincode: </span>
                        <span> {data.orderDtl.PINCODE}</span>
                      </div>
                    </Box>
                  </Typography>
                ) : null}
              </div>
              {/* <Typography variant="body2" className="w-full md:w-auto">
                <Box className="bg-white border rounded-xl px-6 py-4 md:mt-7 mx-2">
                  <div className="my-3 text-xl">
                    <span className="font-bold">Subtotal: </span>
                    <span> ₹{data.ITEM_AMT}</span>
                  </div>
                  <div className="my-3 text-xl">
                    <span className="font-bold">Total: </span>
                    <span> ₹{data.NET_AMT}</span>
                  </div>
                </Box>
              </Typography> */}
              {/* {data.STATUS != "Canceled" && data.STATUS != "Completed" ? (
            <Box className="bg-white border rounded-xl px-6 py-4 mt-7 mx-2 order-first sm:order-1 w-full md:w-auto">
              <FormControl
                variant="outlined"
                style={{
                  width: "200px",
                }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Change Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={orderStatus}
                  onChange={(e) => {
                    setOrderStatus(e.target.value);
                  }}
                  label="status"
                >
                  <MenuItem value={"Pending"}>Pending</MenuItem>
                  <MenuItem value={"Confirm"}>Confirm</MenuItem>
                  <MenuItem value={"Completed"}>Completed</MenuItem>
                  <MenuItem value={"Canceled"}>Canceled</MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                className="ml-5 my-auto"
                onClick={changeOrderStatus}
              >
                Save
              </Button>
            </Box>
          ) : null} */}
            </div>
          </Container>
        ) : (
          <div>
            <div className="grid place-items-center h-screen">
              <CircularProgress className="text-center" />
            </div>
          </div>
        )}
      </OwnerHeader>
    </div>
  );
};

export default OwnerOrderDetails;
