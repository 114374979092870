import React, { useState, useContext, useEffect } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

import GlobalContext from "../../Context/globalContext";

import { useHistory, useParams, Redirect } from "react-router";
import { imageUrl, Instance } from "../../Config";
import { DELETE_CART, SHOW_SNACKBAR } from "../../Context/globalActions.types";
import { decode } from "jsonwebtoken";
import { Link } from "react-router-dom";
import {
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

const Checkout = () => {
  const { globalContext, dispatch } = useContext(GlobalContext);

  const history = useHistory();
  const params = useParams();
  let mobileNo = params.mobileNo;

  const [subTotal, setSubTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pmobileNo, setPmobileNo] = useState("");
  const [addr1, setAddr1] = useState("");
  const [addr2, setAddr2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");

  //Validation Error
  const [isNameValid, setIsNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPmobileNoValid, setIsPmobileNoValid] = useState(true);
  const [isAddr1Valid, setIsAddr1Valid] = useState(true);
  const [isCityValid, setIsCityValid] = useState(true);

  const calculaeTotal = () => {
    setSubTotal(
      globalContext.cart.reduce(
        (accumulator, current) =>
          Number(accumulator) + Number(current.RATE) * Number(current.QUANTITY),
        0
      )
    );
    setTax(
      globalContext.cart.reduce(
        (accumulator, current) =>
          Number(accumulator) +
          (Number(current.NET_AMOUNT) -
            Number(current.RATE) * Number(current.QUANTITY)),
        0
      )
    );
    setTotal(
      globalContext.cart.reduce(
        (accumulator, current) =>
          Number(accumulator) + Number(current.NET_AMOUNT),
        0
      )
    );
  };

  const [isPaymentModeValid, setIsPaymentModeValid] = React.useState(true);
  const [paymentMode, setPaymentMode] = useState("");

  const [advancePayment, setAdvancePayment] = useState("");
  const [remarks1, setRemarks1] = useState("");
  const [remarks2, setRemarks2] = useState("");
  const [remarks3, setRemarks3] = useState("");

  const handleCheckout = () => {
    if (decode(globalContext.user).groupCd == "88") {
      if (name === "") {
        setIsNameValid(false);
        setIsEmailValid(true);
        setIsPmobileNoValid(true);
        setIsAddr1Valid(true);
        setIsCityValid(true);
        setIsPaymentModeValid(true);
        return;
      }

      if (email === "") {
        setIsEmailValid(false);
        setIsNameValid(true);
        setIsPmobileNoValid(true);
        setIsAddr1Valid(true);
        setIsCityValid(true);
        setIsPaymentModeValid(true);
        return;
      }

      if (pmobileNo === "" || pmobileNo.length !== 10) {
        setIsPmobileNoValid(false);
        setIsNameValid(true);
        setIsEmailValid(true);
        setIsAddr1Valid(true);
        setIsCityValid(true);
        setIsPaymentModeValid(true);
        return;
      }

      if (addr1 === "") {
        setIsAddr1Valid(false);
        setIsNameValid(true);
        setIsEmailValid(true);
        setIsPmobileNoValid(true);
        setIsCityValid(true);
        setIsPaymentModeValid(true);
        return;
      }

      if (city === "") {
        setIsCityValid(false);
        setIsNameValid(true);
        setIsEmailValid(true);
        setIsPmobileNoValid(true);
        setIsAddr1Valid(true);
        setIsPaymentModeValid(true);
        return;
      }

      if (paymentMode === "") {
        setIsNameValid(true);
        setIsEmailValid(true);
        setIsPmobileNoValid(true);
        setIsAddr1Valid(true);
        setIsCityValid(true);
        setIsPaymentModeValid(false);
        return;
      }
    }

    const noOfItem = globalContext.cart.reduce(
      (accumulator, current) => Number(accumulator) + Number(current.QUANTITY),
      0
    );

    let postData = {
      partyId: decode(globalContext.user).cd,
      itemAmt: subTotal,
      noOfItem: noOfItem,
      netAmt: total,
      mobileNo: mobileNo,
      paymentMode: paymentMode,
      orderItm: globalContext.cart,
    };

    if (globalContext.user && decode(globalContext.user).groupCd == "88") {
      postData = {
        ...postData,
        orderDtl: {
          NAME: name,
          EMAIL: email,
          MOBILENO: pmobileNo,
          ADD1: addr1,
          ADD2: addr2,
          CITY: city,
          STATE: state,
          PINCODE: pincode,
        },
      };
    }

    if (
      globalContext.settings.filter(
        (e) => e.VARIABLE == "advancePaymentField"
      )[0].VALUE == "Y"
    ) {
      postData = {
        ...postData,
        paidAmt: advancePayment,
      };
    }

    if (
      globalContext.settings.filter((e) => e.VARIABLE == "checkoutRemarks1")[0]
        .VALUE == "Y"
    ) {
      postData = {
        ...postData,
        remarks1: remarks1,
      };
    }

    if (
      globalContext.settings.filter((e) => e.VARIABLE == "checkoutRemarks2")[0]
        .VALUE == "Y"
    ) {
      postData = {
        ...postData,
        remarks2: remarks2,
      };
    }

    if (
      globalContext.settings.filter((e) => e.VARIABLE == "checkoutRemarks3")[0]
        .VALUE == "Y"
    ) {
      postData = {
        ...postData,
        remarks3: remarks3,
      };
    }

    Instance.post("/orders", postData)
      .then((res) => {
        if (res.status == 200) {
          for (let i = 0; i < globalContext.cart.length; i++) {
            Instance.delete(`/cart/${globalContext.cart[i].cartId}`).then(
              (cres) => {
                dispatch({
                  type: DELETE_CART,
                  payload: globalContext.cart[i].cartId,
                });
                if (cres.status == 200 && i == globalContext.cart.length - 1) {
                  history.push(
                    `/${mobileNo}/cart/complete?orderId=${res.data.result.orderId}`
                  );
                }
              }
            );
          }
        }
      })
      .catch((e) => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Order unable to be placed",
            type: "error",
          },
        });
      });
  };

  useEffect(() => {
    calculaeTotal();
  }, [globalContext.cart]);

  if (globalContext.user && decode(globalContext.user).role == "user") {
    return (
      <div>
        <Header>
          <div className="gray py-3">
            <div className="container">
              <div className="row">
                <div className="colxl-12 col-lg-12 col-md-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={`/${params.mobileNo}`}>Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">Support</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Checkout
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <section className="middle">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="text-center d-block mb-5">
                    <h2>Checkout</h2>
                  </div>
                </div>
              </div>

              <div className="row justify-content-between">
                <div className="col-12 col-lg-7 col-md-12">
                  <form>
                    <div>
                      <h5 className="mb-4 ft-medium">Personal Details</h5>
                      <div className="row mb-2">
                        {decode(globalContext.user).groupCd == "88" ? (
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="form-group">
                              <label className="text-dark">Name *</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Abc Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                              {!isNameValid ? (
                                <div className="text-red-500">
                                  Please Enter Proper Name
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : null}

                        {decode(globalContext.user).groupCd == "88" ? (
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="form-group">
                              <label className="text-dark">Email *</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="abc@email.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              {!isEmailValid ? (
                                <div className="text-red-500">
                                  Please Enter Proper Email
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : null}

                        {decode(globalContext.user).groupCd == "88" ? (
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                              <label className="text-dark">Mobile No *</label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="1234567890"
                                value={pmobileNo}
                                onChange={(e) => setPmobileNo(e.target.value)}
                              />
                              {!isPmobileNoValid ? (
                                <div className="text-red-500">
                                  Please Enter Proper Mobile Number
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : null}

                        {decode(globalContext.user).groupCd == "88" ? (
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                              <label className="text-dark">Address 1 *</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Apartment, building, floor"
                                value={addr1}
                                onChange={(e) => setAddr1(e.target.value)}
                              />
                              {!isAddr1Valid ? (
                                <div className="text-red-500">
                                  Please Enter Proper Address 1
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : null}

                        {decode(globalContext.user).groupCd == "88" ? (
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                              <label className="text-dark">Address 2</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address 2"
                                value={addr2}
                                onChange={(e) => setAddr2(e.target.value)}
                              />
                            </div>
                          </div>
                        ) : null}

                        {decode(globalContext.user).groupCd == "88" ? (
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                              <label className="text-dark">City *</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Ahmedabad"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                              />
                              {!isCityValid ? (
                                <div className="text-red-500">
                                  Please Enter Proper City
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : null}

                        {decode(globalContext.user).groupCd == "88" ? (
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                              <label className="text-dark">State</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Gujarat"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                              />
                            </div>
                          </div>
                        ) : null}

                        {decode(globalContext.user).groupCd == "88" ? (
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                              <label className="text-dark">Pincode</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="123456"
                                value={pincode}
                                onChange={(e) => setPincode(e.target.value)}
                              />
                            </div>
                          </div>
                        ) : null}

                        {globalContext.settings.filter(
                          (e) => e.VARIABLE == "advancePaymentField"
                        )[0].VALUE == "Y" ? (
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                              <label className="text-dark">
                                Advance Payment*
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="0"
                                value={advancePayment}
                                onChange={(e) =>
                                  setAdvancePayment(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        ) : null}

                        {globalContext.settings.filter(
                          (e) => e.VARIABLE == "checkoutRemarks1"
                        )[0].VALUE == "Y" ? (
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                              <label className="text-dark">Remarks 1</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Remarks 1"
                                value={remarks1}
                                onChange={(e) => setRemarks1(e.target.value)}
                              />
                            </div>
                          </div>
                        ) : null}

                        {globalContext.settings.filter(
                          (e) => e.VARIABLE == "checkoutRemarks2"
                        )[0].VALUE == "Y" ? (
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                              <label className="text-dark">Remarks 2</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Remarks 2"
                                value={remarks2}
                                onChange={(e) => setRemarks2(e.target.value)}
                              />
                            </div>
                          </div>
                        ) : null}

                        {globalContext.settings.filter(
                          (e) => e.VARIABLE == "checkoutRemarks3"
                        )[0].VALUE == "Y" ? (
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                              <label className="text-dark">Remarks 3</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Remarks 3"
                                value={remarks3}
                                onChange={(e) => setRemarks3(e.target.value)}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Payment Mode
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(e) => {
                          setPaymentMode(e.target.value);
                        }}
                        value={paymentMode == "" ? null : paymentMode}
                      >
                        <FormControlLabel
                          value="Cash on Delivery"
                          control={<Radio />}
                          label="Cash on Delivery"
                        />
                        {/* <FormControlLabel
                          value="Cash against order confirm"
                          control={<Radio />}
                          label="Cash against order confirm"
                        /> */}
                        <FormControlLabel
                          value="As per Company Terms"
                          control={<Radio />}
                          label="As per Company Terms"
                        />
                        <FormControlLabel
                          value="Bank"
                          control={<Radio />}
                          label="Bank"
                        />
                      </RadioGroup>
                    </FormControl>
                    {!isPaymentModeValid ? (
                      <div className="text-red-500">
                        Please Select Payment Mode
                      </div>
                    ) : null}
                  </form>
                </div>

                <div className="col-12 col-lg-4 col-md-12">
                  <div className="d-block mb-3">
                    <h5 className="mb-4">
                      Order Items ({globalContext.cart.length})
                    </h5>
                    <ul className="list-group list-group-sm list-group-flush-y list-group-flush-x mb-4">
                      {globalContext.cart
                        ? globalContext.cart.map((item) => (
                            <li className="list-group-item" key={item.cartId}>
                              <div className="row align-items-center">
                                <div className="col-3">
                                  {item.img ? (
                                    <img
                                      src={imageUrl + "/" + item.img.IMGURL[0]}
                                      alt={item.ITEM_NAME}
                                      width="60"
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src="/img/11.jpg"
                                      width="60"
                                      className="img-fluid"
                                      alt=""
                                    />
                                  )}
                                </div>
                                <div className="col d-flex align-items-center">
                                  <div className="cart_single_caption pl-2">
                                    <h4 className="product_title fs-md ft-medium mb-1 lh-1 capitalize">
                                      {item.ITEM_NAME.toString().toLowerCase()}
                                    </h4>
                                    <p className="mb-1 lh-1">
                                      <span className="text-dark">
                                        Size: {item.SIZE_CD}
                                      </span>
                                    </p>
                                    <h4 className="fs-md ft-medium mb-3 lh-1">
                                      ₹{item.RATE}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>

                  <div className="card mb-4 gray">
                    <div className="card-body">
                      <ul className="list-group list-group-sm list-group-flush-y list-group-flush-x">
                        <li className="list-group-item d-flex text-dark fs-sm ft-regular">
                          <span>Subtotal</span>{" "}
                          <span className="ml-auto text-dark ft-medium">
                            ₹{subTotal}
                          </span>
                        </li>
                        <li className="list-group-item d-flex text-dark fs-sm ft-regular">
                          <span>Tax</span>{" "}
                          <span className="ml-auto text-dark ft-medium">
                            ₹{tax}
                          </span>
                        </li>
                        <li className="list-group-item d-flex text-dark fs-sm ft-regular">
                          <span>Total</span>{" "}
                          <span className="ml-auto text-dark ft-medium">
                            ₹{total}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn btn-block btn-dark mb-3"
                    onClick={handleCheckout}
                  >
                    Place Your Order
                  </button>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </Header>
      </div>
    );
  } else {
    return <Redirect to={`/${mobileNo}/login`} />;
  }
};

export default Checkout;
