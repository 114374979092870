import React, { useEffect, useState, useContext } from "react";
import OwnerHeader from "./Components/OwnerHeader";
import {
  Button,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Divider,
  withStyles,
  Switch,
  InputLabel,
  FormControl,
  Select,
  Input,
  Checkbox,
  MenuItem,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Tooltip,
} from "@material-ui/core";
import { imageUrl, Instance } from "../../Config";
import CustomSnakBar from "../../Components/CustomSnakBar";
import useSWR from "swr";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  INIT_OWNER,
  INIT_SETTINGS,
  SHOW_SNACKBAR,
} from "../../Context/globalActions.types";
import GlobalContext from "../../Context/globalContext";
import CompanyLogoDialog from "./Components/CompanyLogoDialog";
import { resizeImg } from "../../utils/resizeImg";
import StorefrontIcon from "@material-ui/icons/Storefront";
import ViewCarouselOutlinedIcon from "@material-ui/icons/ViewCarouselOutlined";
import StorefrontOutlinedIcon from "@material-ui/icons/StorefrontOutlined";
import CropFreeOutlinedIcon from "@material-ui/icons/CropFreeOutlined";
import BurstModeOutlinedIcon from "@material-ui/icons/BurstModeOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ColorLensOutlinedIcon from "@material-ui/icons/ColorLensOutlined";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import InstagramIcon from "@material-ui/icons/Instagram";
import { useSWRConfig } from "swr";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import ShareIcon from "@material-ui/icons/Share";
import { Skeleton } from "@material-ui/lab";
var QRCode = require("qrcode.react");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
      className="min-h-screen bg-[#F1F5F9]"
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: "#ffffff",
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const OwnerUtility = () => {
  const [isUploading, setIsUploading] = useState({
    isPaymentImgUploading: false,
    isLogoImgUploading: false,
    isBannerUploading: false,
    isBulkUploading: false,
    isSettingsLoading: false,
    isThemeLoading: false,
    isSocialLinkLoading: false,
    isConfigSettingsLoading: false,
  });
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [imageError, setImageError] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  const { mutate } = useSWRConfig();

  const history = useHistory();

  const [isSync, setIsSync] = useState(false);
  const [dataNm, setDataNm] = useState("");

  const { data: resCompany, error: resCompanyError } = useSWR(
    `/saral/company?mobileNo=${params.mobileNo}`
  );

  const [settings, setSettings] = useState([]);
  const [theme, setTheme] = useState({
    primaryColor: "#000000",
    primaryColorText: "#000000",
    secondaryColor: "#000000",
    secondaryColorText: "#000000",
  });

  const [socialLink, setSocialLink] = useState({
    FBLINK: null,
    IGLINK: null,
    YTLINK: null,
    WHANO: null,
  });

  const [configSettings, setConfigSettings] = useState({
    orderNo: "",
    partyGd: "",
  });

  const { globalContext, dispatch } = useContext(GlobalContext);
  const [localImg, setLocalImg] = useState([]);

  const [bulkImgCompleted, setBulkImgCompleted] = useState([]);

  const uploadImage = async (uploadData, width, height) => {
    if (width) {
      const formData = new FormData();
      formData.append("width", width);
      formData.append("height", height);
      for (const image of uploadData) {
        const img = await resizeImg(image, image.type.split("/")[1]);
        formData.append("images", img);
      }
      try {
        const res = await Instance.post("/uploads/images", formData);
        return res.data.result;
      } catch (error) {
        console.log(error);
      }
    } else {
      const formData = new FormData();
      for (const image of uploadData) {
        const img = await resizeImg(image, image.type.split("/")[1]);
        formData.append("images", img);
      }
      try {
        const res = await Instance.post("/uploads/images", formData);
        return res.data.result;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteImage = async (id) => {
    await Instance.delete(`/uploads/${id}`);
  };

  const { data: resOwnerDetail, error: resOwnerDetailError } = useSWR(
    `/owner?mobileNo=${params.mobileNo}`
  );

  const { data: resOwnerSettings, error: resOwnerSettingsError } = useSWR(
    `/settings?mobileNo=${params.mobileNo}`
  );

  const { data: resSpsFileSettings, error: resSpsFileSettingsError } = useSWR(
    `/spsfile?mobileNo=${params.mobileNo}`
  );

  useEffect(() => {
    if (resSpsFileSettings) {
      setDataNm(
        resSpsFileSettings.data.result.find((e) => e.VARIABLE == "dataNm")
          ? resSpsFileSettings.data.result.find((e) => e.VARIABLE == "dataNm")
              .VALUE
          : ""
      );
      setConfigSettings({
        orderNo: resSpsFileSettings.data.result.find(
          (e) => e.VARIABLE == "orderNo"
        )
          ? resSpsFileSettings.data.result.find((e) => e.VARIABLE == "orderNo")
              .VALUE
          : "",
        partyGd: resSpsFileSettings.data.result.find(
          (e) => e.VARIABLE == "partyGd"
        )
          ? resSpsFileSettings.data.result.find((e) => e.VARIABLE == "partyGd")
              .VALUE
          : "",
      });
    }
  }, [resSpsFileSettings]);

  useEffect(() => {
    if (resOwnerSettings) {
      dispatch({
        type: INIT_SETTINGS,
        payload: resOwnerSettings.data.result,
      });
      let temp = [];
      for (let i = 0; i < resOwnerSettings.data.result.length; i++) {
        if (resOwnerSettings.data.result[i].VALUE == "Y") {
          temp.push(resOwnerSettings.data.result[i].VARIABLE);
        }
      }
      setSettings(temp);
    }
  }, [resOwnerSettings]);

  useEffect(() => {
    if (
      resOwnerSettingsError ||
      resOwnerDetailError ||
      resSpsFileSettingsError
    ) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          message: "Something Went Wrong",
          type: "error",
        },
      });
    }
  }, [resOwnerSettingsError, resOwnerDetailError, resSpsFileSettingsError]);

  useEffect(() => {
    if (resOwnerDetail) {
      if (resOwnerDetail.data.result[0].COLOR) {
        setTheme({
          primaryColor: resOwnerDetail.data.result[0].COLOR.primaryColor,
          secondaryColor: resOwnerDetail.data.result[0].COLOR.secondaryColor,
          primaryColorText:
            resOwnerDetail.data.result[0].COLOR.primaryColorText,
          secondaryColorText:
            resOwnerDetail.data.result[0].COLOR.secondaryColorText,
        });
      }
      if (resOwnerDetail.data.result[0]) {
        setSocialLink({
          FBLINK: resOwnerDetail.data.result[0].FBLINK,
          IGLINK: resOwnerDetail.data.result[0].IGLINK,
          YTLINK: resOwnerDetail.data.result[0].YTLINK,
          WHANO: resOwnerDetail.data.result[0].WHANO,
        });
      }
      if (resOwnerDetail.data.result[0].BANNER) {
        setLocalImg(resOwnerDetail.data.result[0].BANNER);
      } else {
        setLocalImg([]);
      }
      dispatch({
        payload: resOwnerDetail.data.result,
        type: INIT_OWNER,
      });
    }
  }, [resOwnerDetail]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  const handleBulkUpload = (images) => {
    function pad(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    }
    const imagesArray = Array.from(images);

    Instance.get("/products/categoryId")
      .then(async (res) => {
        for (
          let i = 1;
          i <= parseInt(res.data.result[0].DEPT_CD.split("E")[1], 10);
          i++
        ) {
          // CATG_1_CODE.jpg
          const categoryCd = `E${pad(i, 3)}`;
          const file = imagesArray.filter(
            (e) => e.name.split("_")[2].split(".")[0] == categoryCd
          );
          if (file.length != 0 && file.length <= 4) {
            const imgUrls = await uploadImage(file, 625, 800);
            Instance.post("/products/bulk", {
              mobileNo: params.mobileNo,
              imgUrl: imgUrls,
              code: categoryCd,
            });
            setBulkImgCompleted(categoryCd);
          }
          if (i == parseInt(res.data.result[0].DEPT_CD.split("E")[1], 10)) {
            Instance.get("/products/productId")
              .then(async (res) => {
                for (
                  let i = 1;
                  i <= parseInt(res.data.result[0].ITEM_CD.split("I")[1], 10);
                  i++
                ) {
                  const itemCd = `I${pad(i, 7)}`;
                  const file = imagesArray.filter(
                    (e) => e.name.split("_")[2].split(".")[0] == itemCd
                  );
                  if (file.length != 0 && file.length <= 4) {
                    const imgUrls = await uploadImage(file, 625, 800);
                    Instance.post("/products/bulk", {
                      mobileNo: params.mobileNo,
                      imgUrl: imgUrls,
                      code: itemCd,
                    });
                    setBulkImgCompleted(itemCd);
                  }
                  if (
                    i == parseInt(res.data.result[0].ITEM_CD.split("I")[1], 10)
                  ) {
                    setIsUploading({
                      ...isUploading,
                      isBulkUploading: false,
                    });
                  }
                }
              })
              .catch(() => {
                dispatch({
                  type: SHOW_SNACKBAR,
                  payload: {
                    message: "Something Went Wrong",
                    type: "error",
                  },
                });
              });
          }
        }
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Something Went Wrong",
            type: "error",
          },
        });
      });
  };

  const handleSettingsSubmit = (e) => {
    e.preventDefault();
    setIsUploading({ ...isUploading, isSettingsLoading: true });
    Instance.put("/settings", {
      mobileNo: params.mobileNo,
      settings: settings,
    })
      .then((res) => {
        mutate(`/settings?mobileNo=${params.mobileNo}`);
        setIsUploading({ ...isUploading, isSettingsLoading: false });
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Settings has been updated successfully",
            type: "success",
          },
        });
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Something Went Wrong",
            type: "error",
          },
        });
      });
  };

  const handleThemeSubmit = (e) => {
    e.preventDefault();
    setIsUploading({ ...isUploading, isThemeLoading: true });
    Instance.put("/owner", {
      type: "color",
      data: theme,
      mobileNo: params.mobileNo,
    })
      .then((res) => {
        if (res.status == 200) {
          setIsUploading({ ...isUploading, isThemeLoading: false });
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              message: "Theme has been updated successfully",
              type: "success",
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Something Went Wrong",
            type: "error",
          },
        });
      });
  };

  const handleSocialLinkSubmit = (e) => {
    e.preventDefault();
    setIsUploading({ ...isUploading, isSocialLinkLoading: true });
    Instance.put("/owner", {
      type: "socialLinks",
      data: socialLink,
      mobileNo: params.mobileNo,
    })
      .then((res) => {
        if (res.status == 200) {
          setIsUploading({ ...isUploading, isSocialLinkLoading: false });
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              message: "Social Links has been updated successfully",
              type: "success",
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Something Went Wrong",
            type: "error",
          },
        });
      });
  };

  const handleSyncBtn = () => {
    setIsSync(true);
    Instance.post("/sync", {
      mobileNo: params.mobileNo,
      dataNm: dataNm,
    })
      .then((res) => {
        if (res.status == 201) {
          setIsSync(false);
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              message: "Data Sync successfully",
              type: "success",
            },
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Something went wrong. Please open the app again.",
            type: "error",
          },
        });
        setIsSync(false);
      });
  };

  const handleConfigSettingsBtn = (e) => {
    e.preventDefault();
    setIsUploading({ ...isUploading, isConfigSettingsLoading: true });
    Instance.put("/spsfile", {
      data: [
        {
          value: configSettings.orderNo,
          variable: "orderNo",
        },
        {
          value: configSettings.partyGd,
          variable: "partyGd",
        },
      ],
      mobileNo: params.mobileNo,
    })
      .then((res) => {
        if (res.status == 200) {
          setIsUploading({ ...isUploading, isConfigSettingsLoading: false });
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              message: "Config Settings has been updated successfully",
              type: "success",
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Something Went Wrong",
            type: "error",
          },
        });
      });
  };

  return (
    <div>
      <OwnerHeader>
        <UploadForm
          deleteImage={deleteImage}
          dispatch={dispatch}
          globalContext={globalContext}
          handleClose={handleClose}
          imageError={imageError}
          isUploading={isUploading}
          localImg={localImg}
          open={open}
          params={params}
          setImageError={setImageError}
          setIsUploading={setIsUploading}
          setLocalImg={setLocalImg}
          uploadImage={uploadImage}
        />
        <CompanyLogoDialog handleClose={handleClose2} open={open2} />
        <BulkUploadForm
          bulkImgCompleted={bulkImgCompleted}
          handleBulkUpload={handleBulkUpload}
          handleClose1={handleClose1}
          isUploading={isUploading}
          open1={open1}
          setIsUploading={setIsUploading}
        />
        <div>
          <div className="md:flex items-center justify-between px-3 md:px-4 py-3">
            <div>
              <div className="text-indigo-500 font-semibold text-sm md:text-base">
                Settings
              </div>
              <div className="text-xl md:text-2xl font-extrabold leading-7 sm:leading-10 truncate mt-1">
                Utilities
              </div>
            </div>
          </div>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            className="ml-2 text-[#3333cc]"
            scrollButtons="auto"
            variant="scrollable"
            TabIndicatorProps={{ style: { background: "#3333cc" } }}
          >
            <Tab label="General" {...a11yProps(0)} />
            <Tab label="Admin Settings" {...a11yProps(1)} />
          </Tabs>
        </div>

        <div className=" bg-[#F1F5F9] min-h-screen pt-3 px-3 pb-5">
          <TabPanel value={tabValue} index={0}>
            <div className="bg-white shadow-sm rounded p-3 ">
              <div className="flex justify-between">
                <div className="font-bold text-xl">
                  <ViewCarouselOutlinedIcon /> Sync Data
                </div>
                <Button
                  variant="contained"
                  disableElevation
                  className=" bg-[#3333cc] text-white"
                  onClick={handleSyncBtn}
                >
                  {isSync ? (
                    <CircularProgress
                      size={25}
                      className="hover:text-white text-black"
                    />
                  ) : (
                    "Sync"
                  )}
                </Button>
              </div>
              <Divider className="mt-2" />
              <div>
                <div className="flex flex-col md:flex-row items-center md:justify-between md:items-start mt-3">
                  {resCompany ? (
                    <FormControl className="md:w-52 w-full">
                      <InputLabel id="demo-mutiple-checkbox-label">
                        Please Select Company
                      </InputLabel>
                      <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        value={dataNm}
                        onChange={(e) => {
                          setDataNm(e.target.value);
                        }}
                        input={<Input />}
                        className="w-72"
                      >
                        {resCompany.data.result.map((name) => (
                          <MenuItem
                            key={name.DATANM}
                            value={name.DATANM}
                            className="px-2"
                          >
                            <p className="text-xs mb-0">
                              {name.fromyr}-{name.toyr} ({name.DATANM}) (
                              {name.comp_name.length > 12
                                ? name.comp_name.split(0, 12) + "..."
                                : name.comp_name.split(0, 12)}
                              )
                            </p>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <Skeleton className="md:w-52 w-full" />
                  )}
                </div>
              </div>
            </div>
            <div className="bg-white shadow-sm rounded p-3 mt-4">
              <div className="flex justify-between">
                <div className="font-bold text-xl">
                  <ViewCarouselOutlinedIcon /> Upload Banner
                </div>
                <Button
                  variant="contained"
                  disableElevation
                  className=" bg-[#3333cc] text-white"
                  onClick={handleClickOpen}
                >
                  Upload
                </Button>
              </div>
              <Divider className="mt-2" />
              <div>
                <div className="flex flex-col md:flex-row items-center md:justify-between md:items-start mt-3">
                  <div>
                    The Recommended size for the Banner is 1528 x 380 Pixels
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white shadow-sm rounded p-3 mt-4">
              <div className="flex justify-between">
                <div className="font-bold text-xl">
                  <StorefrontOutlinedIcon /> Company Logo
                </div>
                <Button
                  variant="contained"
                  disableElevation
                  className="bg-[#3333cc]  text-white"
                  onClick={handleClickOpen2}
                >
                  Upload
                </Button>
              </div>
              <Divider className="mt-2" />
              <div>
                <div className="flex flex-col md:flex-row items-center md:justify-between md:items-start mt-3">
                  <div>
                    The Recommended size for the company Logo is 288 x 72 Pixels
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white shadow-sm rounded p-3 mt-4">
              <div className="flex justify-between">
                <div className="font-bold text-xl">
                  <CropFreeOutlinedIcon /> Payment Image
                </div>
                <div>
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    onChange={async (e) => {
                      if (e.target.files) {
                        if (globalContext.owner[0].PAYMENTIMG) {
                          deleteImage(globalContext.owner[0].PAYMENTIMG);
                        }
                        setIsUploading({
                          ...isUploading,
                          isPaymentImgUploading: true,
                        });
                        const imagesUrl = await uploadImage(e.target.files);
                        Instance.put("/owner", {
                          type: "paymentImg",
                          data: imagesUrl[0],
                          mobileNo: params.mobileNo,
                        })
                          .then((res) => {
                            if (res.status == 200) {
                              globalContext.owner[0].PAYMENTIMG = imagesUrl[0];
                              dispatch({
                                payload: globalContext.owner,
                                type: INIT_OWNER,
                              });
                              setIsUploading({
                                ...isUploading,
                                isPaymentImgUploading: false,
                              });
                            }
                          })
                          .catch(() => {
                            dispatch({
                              type: SHOW_SNACKBAR,
                              payload: {
                                message: "Something Went Wrong",
                                type: "error",
                              },
                            });
                          });
                      }
                    }}
                    style={{
                      display: "none",
                    }}
                  />
                  {isUploading.isPaymentImgUploading ? (
                    <CircularProgress size={30} className="mt-2" />
                  ) : (
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        component="span"
                        disableElevation
                        className="bg-[#3333cc] text-white"
                      >
                        Upload
                      </Button>
                    </label>
                  )}
                </div>
              </div>
              <Divider className="mt-2" />
              <div>
                <div className="flex flex-col md:flex-row items-center md:justify-between md:items-start mt-3">
                  <div>
                    <div>Upload the ScreenShot of QR Code of the Upi.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white shadow-sm rounded p-3 mt-4">
              <div className="flex justify-between">
                <div className="font-bold text-xl">
                  <BurstModeOutlinedIcon /> Bulk Upload Images
                </div>
                <Button
                  variant="contained"
                  disableElevation
                  className="bg-[#3333cc]  text-white"
                  onClick={handleClickOpen1}
                >
                  Upload
                </Button>
              </div>
              <Divider className="mt-2" />
              <div>
                <div className="flex flex-col md:flex-row items-center md:justify-between md:items-start mt-3">
                  <div>
                    <div>
                      <span className="font-bold">Instructions:</span> Select
                      all the images which you want to upload.The name of the
                      images should be in following format ITMS_1_I0000001.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white shadow-sm rounded p-3 mt-4">
              <div className="flex justify-between">
                <div className="font-bold text-xl">
                  <FacebookIcon /> Social Links
                </div>
                <Button
                  variant="contained"
                  disableElevation
                  className="bg-[#3333cc]  text-white"
                  onClick={handleSocialLinkSubmit}
                  disabled={isUploading.isSocialLinkLoading}
                >
                  {isUploading.isSocialLinkLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
              <Divider className="mt-2" />
              <div className="grid md:grid-cols-4 grid-cols-1 gap-4">
                <div className="mt-2">
                  <div className="font-semibold">Facebook Link:</div>
                  <Input
                    className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                    disableUnderline
                    value={socialLink.FBLINK}
                    onChange={(e) => {
                      if (e.target.value == "") {
                        setSocialLink({ ...socialLink, FBLINK: null });
                      } else {
                        setSocialLink({
                          ...socialLink,
                          FBLINK: e.target.value,
                        });
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <FacebookIcon className="text-blue-500" />
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="mt-2">
                  <div className="font-semibold">Youtube Link:</div>
                  <Input
                    className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                    disableUnderline
                    value={socialLink.YTLINK}
                    onChange={(e) => {
                      if (e.target.value == "") {
                        setSocialLink({ ...socialLink, YTLINK: null });
                      } else {
                        setSocialLink({
                          ...socialLink,
                          YTLINK: e.target.value,
                        });
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <YouTubeIcon className="text-red-600" />
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="mt-2">
                  <div className="font-semibold">WhatsApp Number:</div>
                  <Input
                    className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                    disableUnderline
                    value={socialLink.WHANO}
                    onChange={(e) => {
                      if (e.target.value == "") {
                        setSocialLink({ ...socialLink, WHANO: null });
                      } else {
                        setSocialLink({ ...socialLink, WHANO: e.target.value });
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <WhatsAppIcon className="text-green-500" />
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="my-2">
                  <div className="font-semibold">Instagram Link:</div>
                  <Input
                    className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                    disableUnderline
                    value={socialLink.IGLINK}
                    onChange={(e) => {
                      if (e.target.value == "") {
                        setSocialLink({ ...socialLink, IGLINK: null });
                      } else {
                        setSocialLink({
                          ...socialLink,
                          IGLINK: e.target.value,
                        });
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <InstagramIcon className="text-pink-600" />
                      </InputAdornment>
                    }
                  />
                </div>
              </div>
            </div>
            {window.innerWidth < 600 ? (
              <div className="bg-white shadow-sm rounded p-3 mt-4">
                <div className="flex justify-between">
                  <div className="font-bold text-xl">
                    <StorefrontIcon /> Add Shop
                  </div>
                </div>
                <Divider className="mt-2" />
                <div>
                  <div className="flex flex-col md:flex-row items-center md:justify-between md:items-start mt-3">
                    <Typography className="px-4 pt-1">
                      <Link to={`/${params.mobileNo}/addshop`}>Add Shop</Link>
                    </Typography>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="bg-white shadow-sm rounded p-3 mt-4">
              <div className="flex justify-between">
                <div className="font-bold text-xl">
                  <ShareIcon /> Share
                </div>
                <Button
                  variant="outlined"
                  className=" bg-[#3333cc] text-white"
                  onClick={() => {
                    if (window.innerWidth < 600) {
                      history.push(
                        `/${params.mobileNo}/owner/utility?link=${globalContext.owner[0].APPLINK}&type=share`
                      );
                    } else {
                      navigator.share({
                        url:
                          globalContext.owner && globalContext.owner[0]
                            ? globalContext.owner[0].APPLINK
                              ? globalContext.owner[0].APPLINK
                              : ""
                            : "",
                        title: globalContext.company
                          ? globalContext.company.length != 0
                            ? globalContext.company[0].COMP_NAME
                            : ""
                          : "",
                      });
                    }
                  }}
                >
                  Share
                </Button>
              </div>
              <Divider className="mt-2" />
              <div>
                <div className="flex flex-col md:flex-row items-center md:justify-between md:items-start mt-3">
                  <Typography className="px-4 pt-2 pb-1">
                    <QRCode
                      value={
                        globalContext.owner && globalContext.owner[0]
                          ? globalContext.owner[0].APPLINK
                            ? globalContext.owner[0].APPLINK
                            : ""
                          : ""
                      }
                      includeMargin
                      size={200}
                    />
                  </Typography>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <div className="bg-white shadow-sm rounded p-3">
              <div className="flex justify-between">
                <div className="font-bold text-xl mb-1">
                  <ColorLensOutlinedIcon /> Update Theme
                </div>
                <Button
                  variant="contained"
                  disableElevation
                  className="bg-[#3333cc] text-white"
                  onClick={handleThemeSubmit}
                >
                  {isUploading.isThemeLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>

              <Divider className="mt-2" />
              <div>
                <div className="flex justify-between mt-3">
                  <div>Primary Color :</div>
                  <input
                    type="color"
                    value={theme.primaryColor}
                    onChange={(e) => {
                      setTheme({ ...theme, primaryColor: e.target.value });
                    }}
                  />
                </div>
                <div className="flex justify-between mt-3">
                  <div>Primary Color Text :</div>
                  <input
                    type="color"
                    value={theme.primaryColorText}
                    onChange={(e) => {
                      setTheme({
                        ...theme,
                        primaryColorText: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="flex justify-between mt-3">
                  <div>Secondary Color :</div>
                  <input
                    type="color"
                    value={theme.secondaryColor}
                    onChange={(e) => {
                      setTheme({ ...theme, secondaryColor: e.target.value });
                    }}
                  />
                </div>
                <div className="flex justify-between mt-3">
                  <div>Secondary Color Text :</div>
                  <input
                    type="color"
                    value={theme.secondaryColorText}
                    onChange={(e) => {
                      setTheme({
                        ...theme,
                        secondaryColorText: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="bg-white shadow-sm rounded p-3 mt-4">
              <div className="flex justify-between">
                <div className="font-bold text-xl">
                  <SettingsOutlinedIcon /> Update Settings
                </div>
                <Button
                  variant="contained"
                  disableElevation
                  className="bg-[#3333cc] text-white"
                  onClick={handleSettingsSubmit}
                >
                  {isUploading.isSettingsLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
              <Divider className="mt-2" />
              <div>
                <div className="flex justify-between mt-3">
                  <div className="flex items-center">
                    <div>Party Wise Rate</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "prtyRate"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "prtyRate"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "prtyRate")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "prtyRate"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "prtyRate")
                            );
                          }
                        }}
                        name="PartyWiseRate"
                      />
                    }
                  />
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Item Discount</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "itemDisc"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "itemDisc"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "itemDisc")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "itemDisc"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "itemDisc")
                            );
                          }
                        }}
                        name="ItemDiscount"
                      />
                    }
                  />
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Guest Login</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "guestLogin"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "guestLogin"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "guestLogin")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "guestLogin"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "guestLogin")
                            );
                          }
                        }}
                        name="GuestLogin"
                      />
                    }
                  />
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Show Guest Rate</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "guestRate"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "guestRate"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "guestRate")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "guestRate"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "guestRate")
                            );
                          }
                        }}
                        name="ShowGuestRate"
                      />
                    }
                  />
                </div>

                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Show Stock</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "showStk"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "showStk"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "showStk")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "showStk"]);
                          } else {
                            setSettings(settings.filter((e) => e != "showStk"));
                          }
                        }}
                        name="ShowStk"
                      />
                    }
                  />
                </div>
                {settings.find((e) => e == "showStk") ? (
                  <div className="flex justify-between">
                    <div className="flex items-center">
                      <div>Party Wise Stock</div>
                      <Tooltip
                        title={
                          globalContext.settings.length != 0
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "prtyStk"
                              )
                              ? globalContext.settings.find(
                                  (e) => e.VARIABLE == "prtyStk"
                                ).DESCRI
                              : ""
                            : ""
                        }
                        arrow
                        placement="right"
                      >
                        <InfoOutlinedIcon fontSize="small" className="ml-2" />
                      </Tooltip>
                    </div>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={
                            settings.length != 0
                              ? settings.find((e) => e == "prtyStk")
                                ? true
                                : false
                              : false
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setSettings([...settings, "prtyStk"]);
                            } else {
                              setSettings(
                                settings.filter((e) => e != "prtyStk")
                              );
                            }
                          }}
                          name="ShowPartyWiseStock"
                        />
                      }
                    />
                  </div>
                ) : null}
                {/* <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Check Stock</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "checkStk"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "checkStk"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "checkStk")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "checkStk"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "checkStk")
                            );
                          }
                        }}
                        name="CheckStk"
                      />
                    }
                  />
                </div> */}
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Show Product Without Img</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "showPrdWithoutImg"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "showPrdWithoutImg"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "showPrdWithoutImg")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "showPrdWithoutImg"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "showPrdWithoutImg")
                            );
                          }
                        }}
                        name="showPrdWithoutImg"
                      />
                    }
                  />
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Show Only Products</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "showOnlyProducts"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "showOnlyProducts"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "showOnlyProducts")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "showOnlyProducts"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "showOnlyProducts")
                            );
                          }
                        }}
                        name="showOnlyProducts"
                      />
                    }
                  />
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Show Sign Up</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "showSignUp"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "showSignUp"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "showSignUp")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "showSignUp"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "showSignUp")
                            );
                          }
                        }}
                        name="showSignUp"
                      />
                    }
                  />
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Personal App</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "personalApp"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "personalApp"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "personalApp")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "personalApp"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "personalApp")
                            );
                          }
                        }}
                        name="personalApp"
                      />
                    }
                  />
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Custom Order Label</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "customOrderLabel"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "customOrderLabel"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "customOrderLabel")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "customOrderLabel"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "customOrderLabel")
                            );
                          }
                        }}
                        name="customOrderLabel"
                      />
                    }
                  />
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Sync Sman</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "syncSman"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "syncSman"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "syncSman")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "syncSman"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "syncSman")
                            );
                          }
                        }}
                        name="syncSman"
                      />
                    }
                  />
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Delete Account</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "deleteAccount"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "deleteAccount"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "deleteAccount")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "deleteAccount"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "deleteAccount")
                            );
                          }
                        }}
                        name="deleteAccount"
                      />
                    }
                  />
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Enable UOM</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "enableUom"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "enableUom"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "enableUom")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "enableUom"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "enableUom")
                            );
                          }
                        }}
                        name="enableUom"
                      />
                    }
                  />
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Prol</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "prol"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "prol"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "prol")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "prol"]);
                          } else {
                            setSettings(settings.filter((e) => e != "prol"));
                          }
                        }}
                        name="prol"
                      />
                    }
                  />
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Lock New User</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "lockNewUser"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "lockNewUser"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "lockNewUser")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "lockNewUser"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "lockNewUser")
                            );
                          }
                        }}
                        name="lockNewUser"
                      />
                    }
                  />
                </div>

                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Advance Payment Field</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "advancePaymentField"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "advancePaymentField"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "advancePaymentField")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "advancePaymentField"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "advancePaymentField")
                            );
                          }
                        }}
                        name="advancePaymentField"
                      />
                    }
                  />
                </div>

                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Remarks 1 Field</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "checkoutRemarks1"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "checkoutRemarks1"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "checkoutRemarks1")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "checkoutRemarks1"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "checkoutRemarks1")
                            );
                          }
                        }}
                        name="checkoutRemarks1"
                      />
                    }
                  />
                </div>

                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Remarks 2 Field</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "checkoutRemarks2"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "checkoutRemarks2"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "checkoutRemarks2")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "checkoutRemarks2"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "checkoutRemarks2")
                            );
                          }
                        }}
                        name="checkoutRemarks2"
                      />
                    }
                  />
                </div>

                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div>Remarks 3 Field</div>
                    <Tooltip
                      title={
                        globalContext.settings.length != 0
                          ? globalContext.settings.find(
                              (e) => e.VARIABLE == "checkoutRemarks3"
                            )
                            ? globalContext.settings.find(
                                (e) => e.VARIABLE == "checkoutRemarks3"
                              ).DESCRI
                            : ""
                          : ""
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" className="ml-2" />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={
                          settings.length != 0
                            ? settings.find((e) => e == "checkoutRemarks3")
                              ? true
                              : false
                            : false
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSettings([...settings, "checkoutRemarks3"]);
                          } else {
                            setSettings(
                              settings.filter((e) => e != "checkoutRemarks3")
                            );
                          }
                        }}
                        name="checkoutRemarks3"
                      />
                    }
                  />
                </div>
              </div>
            </div>
            <div className="bg-white shadow-sm rounded p-3 mt-4">
              <div className="flex justify-between">
                <div className="font-bold text-xl">
                  <SettingsOutlinedIcon /> Update Config Settings
                </div>
                <Button
                  variant="contained"
                  disableElevation
                  className="bg-[#3333cc] text-white"
                  onClick={handleConfigSettingsBtn}
                >
                  {isUploading.isConfigSettingsLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
              <Divider className="mt-2" />
              <div>
                <div className="grid md:grid-cols-4 grid-cols-1 gap-3">
                  <div className="mt-2">
                    <div className="font-semibold">Order No:</div>
                    <Input
                      className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                      disableUnderline
                      value={configSettings.orderNo}
                      onChange={(e) => {
                        setConfigSettings({
                          ...configSettings,
                          orderNo: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mt-2">
                    <div className="font-semibold">Party Grade:</div>
                    <Input
                      className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                      disableUnderline
                      value={configSettings.partyGd}
                      onChange={(e) => {
                        setConfigSettings({
                          ...configSettings,
                          partyGd: e.target.value.toUpperCase(),
                        });
                      }}
                      inputProps={{ maxLength: 1 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
      </OwnerHeader>
    </div>
  );
};

export default OwnerUtility;

const UploadForm = ({
  open,
  handleClose,
  setImageError,
  localImg,
  setIsUploading,
  isUploading,
  setLocalImg,
  uploadImage,
  imageError,
  deleteImage,
  params,
  globalContext,
  dispatch,
}) => {
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          <div className="flex justify-between">
            <p>Upload Images</p>
            <label htmlFor="contained-button-file3">
              <input
                accept="image/*"
                id="contained-button-file3"
                multiple
                type="file"
                style={{
                  display: "none",
                }}
                onChange={async (e) => {
                  setImageError(false);
                  const total = e.target.files.length + localImg.length;
                  if (total <= 10) {
                    setIsUploading({
                      ...isUploading,
                      isBannerUploading: true,
                    });
                    const imgUrls = await uploadImage(
                      e.target.files,
                      1920,
                      900
                    );
                    setLocalImg(localImg.concat(imgUrls));
                    setIsUploading({
                      ...isUploading,
                      isBannerUploading: false,
                    });
                  } else {
                    setImageError(true);
                  }
                }}
              />
              <Button
                variant="contained"
                disableElevation
                className=" bg-[#3333cc] text-white"
                component="span"
              >
                Select Image
              </Button>
              {imageError ? (
                <div className="text-sm pt-2 text-red-500">
                  You can upload only 5 images.
                </div>
              ) : (
                ""
              )}
            </label>
          </div>
        </DialogTitle>
        <DialogContent className="wrapper">
          {isUploading.isBannerUploading ? (
            <div className="flex justify-center items-center">Loading...</div>
          ) : localImg ? (
            localImg.map((im) => (
              <div className="cs-card" key={im}>
                <img src={imageUrl + "/" + im} />
                <div className="cs-info">
                  <button
                    onClick={() => {
                      deleteImage(im);
                      setLocalImg(localImg.filter((e) => e != im));
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))
          ) : (
            "No Image found"
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text">
            Close
          </Button>
          <Button
            onClick={() => {
              Instance.put("/owner", {
                type: "banner",
                data: localImg.length == 0 ? null : localImg,
                mobileNo: params.mobileNo,
              })
                .then((res) => {
                  if (res.status == 200) {
                    globalContext.owner[0].BANNER = localImg;
                    dispatch({
                      payload: globalContext.owner,
                      type: INIT_OWNER,
                    });
                    handleClose();
                  }
                })
                .catch(() => {
                  dispatch({
                    type: SHOW_SNACKBAR,
                    payload: {
                      message: "Something Went Wrong",
                      type: "error",
                    },
                  });
                });
            }}
            variant="text"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const BulkUploadForm = ({
  open1,
  setIsUploading,
  isUploading,
  handleBulkUpload,
  handleClose1,
  bulkImgCompleted,
}) => {
  return (
    <div>
      <Dialog open={open1} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          <div className="flex justify-between">
            <p>Bulk Upload Images</p>
            <label htmlFor="contained-button-file4">
              <input
                accept="image/*"
                id="contained-button-file4"
                multiple
                type="file"
                style={{
                  display: "none",
                }}
                onChange={async (e) => {
                  setIsUploading({
                    ...isUploading,
                    isBulkUploading: true,
                  });
                  handleBulkUpload(e.target.files);
                }}
              />
              <Button
                variant="contained"
                disableElevation
                className=" bg-[#3333cc] text-white"
                component="span"
              >
                Select Image
              </Button>
            </label>
          </div>
        </DialogTitle>
        <DialogContent className="wrapper">
          {isUploading.isBulkUploading ? (
            <div className="flex justify-center items-center">
              <Typography variant="body2">
                Current items images uploading: {bulkImgCompleted}
              </Typography>
            </div>
          ) : (
            "Please Select Images to Upload"
          )}
        </DialogContent>
        <DialogActions>
          {!isUploading.isBulkUploading ? (
            <Button onClick={handleClose1} variant="text">
              Close
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
};
